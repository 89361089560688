import { createFileRoute } from '@tanstack/react-router';

import { ensureInfiniteQueryData } from '@/common/util/ensureInfiniteQueryData.ts';
import { listTeamUserQuery } from '@/features/administrator-panel/team/api/teamManagementApi.ts';
import { TEAM_USERS_PER_PAGE } from '@/features/administrator-panel/team/constants.ts';

export const Route = createFileRoute('/admin/configuration/team/')({
  validateSearch: (search: Record<string, unknown>) => {
    return {
      search: (search.search as string) || undefined,
    };
  },
  loader: () =>
    ensureInfiniteQueryData(
      listTeamUserQuery({ perPage: TEAM_USERS_PER_PAGE }),
    ),
});
